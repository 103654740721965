<template>
  <div class="page-approval-by-me">
    <h2>历史审核记录</h2>
    <van-cell-group class="apply-list"
                    inset
                    v-for="item in approvalList"
                    :key="item.id">
      <van-cell size="large">
        <b>{{ item.name }}:{{ item.formKeyDesc }}</b>
      </van-cell>
      <!-- <van-cell size="large"
                title="申请者">
        {{ item.assignee || "岗位审核" }}
      </van-cell> -->
      <van-cell size="large"
                title="创建时间">
        {{ item.endTime | date }}
      </van-cell>

      <van-cell size="large"
                center>
        <template>
          <div style="text-align: center;">

            <van-button style="width:3rem;"
                        size="small"
                        round
                        type="info"
                        @click="
                newBpmnApproval({
                  title: '查看审核',
                  formKeyName: item.formKeyName,
                  formKeyDesc: item.formKeyDesc,
                  args: { name: '查看审核' },
                  query: {
                    processInstanceId: item.processInstanceId,
                    formKey: item.formKey,
                    taskId: item.id
                  }
                })
              ">
              查看
            </van-button>
            <!-- <van-button style="width:3rem;margin-left:0.5rem;"
                        plain
                        size="small"
                        round
                        type="info"
                        @click="showBpmnInstanceDiagram(item)">
              查看
            </van-button> -->
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-empty v-if="!approvalList.length"
               description="暂无审批" />

    <bpmnInstanceDiagram ref="bpmnInstanceDiagram"></bpmnInstanceDiagram>
  </div>
</template>
<script>
import "@/assets/less/views/approval/approvalByMe.less";
import { SUCCESS_CODE } from "@/apis/notification.js";
import { sortBy } from "lodash-es";
import bpmnInstanceDiagram from "./bpmnInstanceDiagram.vue";
import { mapState } from "vuex";
import formUtils from "../../entry/form/components/forms/utils.js";
export default {
  data() {
    return {
      processKeyList: [],

      approvalList: [],
      formList: []
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    async init() {
      this.approvalList = [];
      await this.getFormList();
      // await this.getTaskByAssignee();
      await this.getHistoryTaskOfMe();
    },
    async getProcessInstanceVaribles(processInstanceId) {
      if (!processInstanceId) return {};
      let ret = await this.$api.getProcessInstanceVaribles({
        placeholder: { id: processInstanceId }
      });
      if (ret.code != SUCCESS_CODE) return {};
      let data = {};
      Object.keys(ret.data).forEach(item => {
        data[item] = ret.data[item].value;
      });
      return data;
    },
    async getFormList() {
      let ret = await this.$api.getFormList({ async: false });
      if (ret.code != SUCCESS_CODE) return;
      this.formList = ret.data;
    },
    async newBpmnApproval(to) {
      var url;
      if (!to.args) to.args = {};

      if (to.query.processKey) {
        url = this.$utils.getFormUrlByKey(to.query.formKey, to.query.formKey == "classroomReserve" ? { classroomId: 1 } : {});
      } else {
        let varibles = await formUtils.getHistoryProcessInstanceVaribles(to.query.processInstanceId);
        varibles.query.renderFormKey = varibles.query.renderFormKey.toString();

        delete to.query.formKey;
        let url = this.$utils.getFormUrlByKey("classroomReserveDetail", {
          state: 'COMPLETED',
          renderFormKey: varibles.query.renderFormKey,
          processInstanceId: to.query.processInstanceId,
          ...varibles.query,
          ...to.query,
          readOnly: 1
        });
        location.href = url;
      }
    },
    // showBpmnInstanceDiagram(row) {
    //   row.rootProcessInstanceId = row.processInstanceId;
    //   this.$refs["bpmnInstanceDiagram"].viewDetail({ ...row, state: "ACTIVE" });
    // },
    async getHistoryTaskOfMe() {
      let ret = await this.$api.getApprovalList({
        params: {
          sortBy: "endTime",
          sortOrder: "desc",
          taskAssignee: this.userInfo.userId,
        }
      });
      if (ret.code != SUCCESS_CODE) return;

      let data = ret.data;
      data.forEach(item => {
        item.endTime = this.$moment(item.endTime).format("YYYY-MM-DD HH:mm");
        let formKeyIdx = this.formList.findIndex(it => {
          return it.name == "approval";
        });
        if (formKeyIdx !== -1) {
          item.formKeyName = this.formList[formKeyIdx].name;
          item.formKeyDesc = this.formList[formKeyIdx].description;
        }
      });

      this.approvalList = data;
    },
  },
  components: {
    bpmnInstanceDiagram
  }
};
</script>
